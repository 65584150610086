(function($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();
    $(document).on('lazybeforeunveil', function (e) {
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    $(document).on("click", "[data-lightgallery-open]", function (e) {
        e.preventDefault();
        var $gallery_data = $(this).data('lightgallery-open');
        $(this).lightGallery({
            dynamic: true,
            actualSize: false,
            zoom: false,
            dynamicEl: $gallery_data
        });
    });

    if ($("[data-form-datepicker]").length) {
        var $lang = $("html").attr('lang'),
            $checkin = $('[data-form-datepicker="arrival"]'),
            $checkout = $('[data-form-datepicker="departure"]');
        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker." + $lang + ".min.js").done(function () {
            $checkin.find("[data-arrival]").datepicker({
                language: $lang,
                position: "top center",
                minDate: new Date(),
                altField: $checkin.find("[data-arrival-alt]"),
                altFieldDateFormat: "yyyy-mm-dd",
                autoClose: true,
                onSelect: function (fd, d) {
                    d.setDate(d.getDate()+1);
                    $checkout.find("[data-departure]").datepicker().data('datepicker').selectDate(d);
                    $checkout.find("[data-departure]").datepicker().data('datepicker').update({
                        minDate: d
                    });
                }
            });
            $checkout.find("[data-departure]").datepicker({
                language: $lang,
                position: "top center",
                minDate: new Date(),
                altField: $checkout.find("[data-departure-alt]"),
                altFieldDateFormat: "yyyy-mm-dd",
                autoClose: true
            })
        });
    }

    if ($("[data-masonry-grid]").length) {
        $.getScript(cdnjs.masonry).done(function () {
            $("[data-masonry-grid]").masonry({
                itemSelector: '.col--masonry',
                columnWidth: '.col--masonry-width',
                percentPosition: true
            })
        });
    }

    nl_lib_dialog.init();
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    var picture = document.createElement('picture'),
        strObj = '' + picture;
    if (strObj.indexOf("HTMLPictureElement") == -1 && $(window).width() < 1023) {
        $.getScript(cdnjs.picturefill);
    }


    // AJAX FORM //
    $(document).on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        var $frm = $(this);
        var formData = new FormData($(this)[0]);
        $.ajax({
            method: $frm.attr('method'),
            url: $frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false
        }).done(function () {
            if($(window).width() < 768) {
                $('html, body').animate({
                    scrollTop: $frm.offset().top
                }, 500);
            }
        });
    });

    var antispam = function () {
        if ($('#newsletterForm').length) {
            setTimeout(function () {
                $('#newsletterForm').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 3000);
        }
    };

    var antispamContact = function() {
        if ($('#snippet-contactform').length) {
            setTimeout(function() {
                $('#snippet-contactform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 3000);
        }
    };

    antispam();

    if ($('#snippet-contactform').length) {
        antispamContact();
    }

    /* GLOBAL AJAX EVENT HANDLER */
    $.ajaxSetup({
        success: function (payload) {
            if (payload) {
                if (payload.redirect) {
                    if (payload.redirect == 'this') {
                        location.href = window.location.href.split("#")[0];
                    } else {
                        location.href = payload.redirect;
                    }
                }
                if (payload.snippets) {
                    for (var i in payload.snippets) {
                        $("#" + i).replaceWith(payload.snippets[i]);
                    }
                }
                if (payload.replaceState) {
                    window.history.replaceState(null, null, payload.replaceState.url);
                    //document.title = payload.replaceState.title;
                }
                antispam();
                antispamContact();
            }
        },
        dataType: "json"
    });

// galerie
    $(document).on('click', 'a.ajax', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href')
        }).done(function () {
            window.history.pushState("", "", $el.attr('href'));
            if ($el.closest(".wrp_content_nav").length) {
                $el.addClass("mod--active").parent().siblings("li").children("a").removeClass("mod--active");
            }
            fn_libs_reload();
            fn_comp_gallery_lightgallery($("[data-gallery]"));
        });
    });

// blog
    $(document).on('click', '.blogList', function(e){
        $('.blogList').removeClass('mod--active');
        $(this).addClass('mod--active');
    });
})(jQuery);
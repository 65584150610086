function fn_lib_dialog() {
    var $body = $("body");
    var $dialog = $("[data-dialog]");

    function fn_lib_dialog_show(data) {
        $(document).find(".elm_dialog_content").html('<button class="elm_dialog_close icon--close" data-dialog-close></button>');
        if (!$(document).find(".lib--dialog").length) {
            $body.addClass("mod--no-scroll").append('<div class="lib--dialog"><div class="elm_dialog_background"></div><div class="elm_dialog_content"><button class="elm_dialog_close icon--close" data-dialog-close></button></div></div>');
        }
        $(document).find(".elm_dialog_content").append(data).hide().fadeIn(300);
        $(document).find(".elm_dialog_background").fadeIn(300);
    }

    function fn_lib_dialog_hide() {
        $body.removeClass("mod--no-scroll");
        $dialog.removeClass("mod--active");
        $(document).find(".elm_dialog_background").fadeOut(300);
        $(document).find(".elm_dialog_content").fadeOut(300);

        setTimeout(function () {
            $(document).find(".lib--dialog").remove();
        }, 500);
    }

    function fn_lib_dialog_init() {
        $(document).on('click', '[data-dialog]', function (e) {
            e.preventDefault();
            var $el = $(this);
            $el.addClass("mod--active");
            $.ajax({
                dataType: 'html',
                url: $el.data('dialog')
            }).done(function (data) {
                fn_lib_dialog_show(data);
            });
        });

        $(document).on("click", ".elm_dialog_background, [data-dialog-close]", function (e) {
            e.preventDefault();
            fn_lib_dialog_hide();
        });
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init
    };
}
var nl_lib_dialog = fn_lib_dialog();